var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white"
  }, [_vm._v(_vm._s(_vm.$t("trust-product")))])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-8 offset-md-2 mt-4 pt-2"
  }, [_vm.error == 1 ? _c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v(" " + _vm._s(_vm.$t("choose-product")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('div', {
    on: {
      "click": function ($event) {
        return _vm.pushRedirect(2);
      }
    }
  }, [_c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.$t("corporate-trust-declaration")))]), _c('div', {
    staticClass: "mt-4"
  }, [_c('p', {
    staticClass: "h6 text-muted mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("corporate-trust-declaration-info")) + " ")])])]), _vm._m(0)])])])])]), _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('div', {
    on: {
      "click": function ($event) {
        return _vm.pushRedirect(4);
      }
    }
  }, [_c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.$t("cash-trust-declaration")))]), _c('div', {
    staticClass: "mt-4"
  }, [_c('p', {
    staticClass: "h6 text-muted mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("cash-trust-declaration-info")) + " ")])])]), _vm._m(1)])])])])]), _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('div', {
    on: {
      "click": function ($event) {
        return _vm.pushRedirect(6);
      }
    }
  }, [_c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.$t("property-trust-declaration")))]), _c('div', {
    staticClass: "mt-4"
  }, [_c('p', {
    staticClass: "h6 text-muted mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("property-trust-declaration-info")) + " ")])])]), _vm._m(2)])])])])]), _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('div', {
    on: {
      "click": function ($event) {
        return _vm.pushRedirect(8);
      }
    }
  }, [_c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.$t("cash-trust")))]), _c('div', {
    staticClass: "mt-4"
  }, [_c('p', {
    staticClass: "h6 text-muted mb-0"
  }, [_vm._v(_vm._s(_vm.$t("cash-trust-info")))])])]), _vm._m(3)])])])])]), _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('div', {
    on: {
      "click": function ($event) {
        return _vm.pushRedirect(10);
      }
    }
  }, [_c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.$t("property-trust")))]), _c('div', {
    staticClass: "mt-4"
  }, [_c('p', {
    staticClass: "h6 text-muted mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("property-trust-info")) + " ")])])]), _vm._m(4)])])])])]), _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('div', {
    on: {
      "click": function ($event) {
        return _vm.pushRedirect(11);
      }
    }
  }, [_c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.$t("business-trust")))]), _c('div', {
    staticClass: "mt-4"
  }, [_c('p', {
    staticClass: "h6 text-muted mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("business-trust-info")) + " ")])])]), _vm._m(5)])])])])]), _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('div', {
    on: {
      "click": function ($event) {
        return _vm.pushRedirect(14);
      }
    }
  }, [_c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.$t("estate-trust")))]), _c('div', {
    staticClass: "mt-4"
  }, [_c('p', {
    staticClass: "h6 text-muted mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("estate-trust-info")) + " ")])])]), _vm._m(6)])])])])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })]);
}]

export { render, staticRenderFns }